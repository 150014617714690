<template>
  <b-overlay :show="loadingUsers" spinner-variant="primary">
    <k-data-table
      id="AuthorsTable"
      :options="dtOptions"
      :dataList="users"
      class="table-hover simple-table dataTable"
      ref="datatable"
    >
    </k-data-table>
    <k-subscribe-event
      :events="['data-update:user']"
      @action="GetUsers"
    ></k-subscribe-event>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { userService } from '../../services/UserService';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { ModalFunctions } from '../../functions/ModalFunctions';
import { eventBus } from '../../main';
import { addTooltip } from '../../utils/common';
import { AppStoreTypes } from '../../store/modules/app';
import { AppFunctions } from '../../functions/AppFunctions';
import BaseModal from '../modals/common/BaseModal.vue';

export default {
  name: 'authors-table',
  model: {
    prop: 'selected',
    event: 'change',
  },
  data() {
    return {
      loadingUsers: false,
      users: [],
    };
  },
  mounted() {},
  beforeDestroy() {},
  created() {
    this.GetUsers();
  },
  methods: {
    SetSelectedItem(ids) {
      this.$emit('change', ids);
    },
    async GetUsers() {
      this.loadingUsers = true;
      try {
        let users = await userService.GetAuthors();
        this.users = Object.freeze(users);
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.loadingUsers = false;
    },
    DrawSelectsOnDT(ids) {
      var selected = ids
        .map(m => {
          return `[data-id="${m.id}"]`;
        })
        .join(',');
      var selectedIdsInDt = Array.from(
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows({ selected: true })
          .data()
      ).map(m => m.Id);
      let removedFromDt = selectedIdsInDt.filter(
        f => !ids.some(s => s.id == f)
      );

      let notSelected = removedFromDt
        .map(m => {
          return `[data-id="${m}"]`;
        })
        .join(',');

      if (selected) {
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows(selected, { selected: false })
          .select();
      }
      if (notSelected) {
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows(notSelected, { selected: true })
          .deselect();
      }
    },
  },
  computed: {
    ...mapGetters({ user: AppStoreTypes.user.get }),
    isGod() {
      return AppFunctions.IsGod(this.user);
    },
    isProjektAdmin() {
      return AppFunctions.IsProjektAdmin(this.user);
    },
    dtOptions() {
      let vm = this;
      let tableData = this.tableData;
      let dtOptions = {
        initComplete: function(settings, json) {
          var table = '#' + settings.sTableId;
          $(vm.$refs.datatable.$el)
            .DataTable()
            .on('select', function(e, dt, type, indexes) {
              let rows = dt.rows({ selected: true }).data();
              vm.SetSelectedItem(
                Array.from(rows).map(m => {
                  return {
                    id: m.Id,
                    text: m.Nev,
                  };
                })
              );
            });
          $(vm.$refs.datatable.$el)
            .DataTable()
            .on('deselect', function(e, dt, type, indexes) {
              let rows = dt.rows({ selected: true }).data();
              vm.SetSelectedItem(
                Array.from(rows).map(m => {
                  return {
                    id: m.Id,
                    text: m.Nev,
                  };
                })
              );
            });

          $(table + ' .clear-searchbar').click(function() {
            $('.dataTables_filter .form-control').val('');
            $(vm.$refs.datatable.$el)
              .DataTable()
              .search('')
              .draw();
          });
        },
        mark: {
          className: 'mark-red',
        },
        order: [[3, 'desc']],
        bSortClasses: false,
        aoColumns: [
          // {
          //   targets: 0,
          //   mDataProp: 'Id',
          //   sTitle: '',
          //   sWidth: 50,
          //   bSortable: false,
          //   responsivePriority: 1,
          //   sClass: 'select-checkbox d-print-none dt-td-noClick all',
          //   checkboxes: {
          //     selectRow: true,
          //     selectAllRender:
          //       '<div class="checkbox"><input type="checkbox" class="dt-checkboxes"><label></label></div>',
          //     selectCallback: function(n, s) {
          //       // console.log('selectCallback', n, s);
          //     },
          //     selectAllCallback: function(nodes, isSelected, isIndeterminate) {
          //       if (isSelected && !isIndeterminate) {
          //         $(vm.$el)
          //           .find('th.select-checkbox')
          //           .closest('tr')
          //           .addClass('selected');
          //       } else {
          //         $(vm.$el)
          //           .find('th.select-checkbox')
          //           .closest('tr')
          //           .removeClass('selected');
          //       }
          //     },
          //   },
          //   mRender: function(data, type, row, meta) {
          //     if (type === 'display') {
          //       data =
          //         '<div class="checkbox"><input type="checkbox" class="dt-checkboxes"><label></label></div>';
          //     }

          //     return '';
          //   },
          // },
          {
            sTitle: 'Név',
            sClass: 'width-fit-content',
            mRender: function(data, type, row, meta) {
              return row.Nev;
            },
          },
          {
            sTitle: 'Elérhetőség',
            sClass: '',
            mRender: function(data, type, row, meta) {
              return row.Email + '<br/>' + row.Telefonszam;
            },
          },
          {
            sTitle: 'Szervezet',
            sClass: '',
            mRender: function(data, type, row, meta) {
              return row.Szervezet?.Text ?? '-';
            },
          },
          {
            sTitle: 'Utolsó aktivitás',
            sClass: '',
            mRender: function(data, type, row, meta) {
              if (row.UtolsoBejelentkezesDatuma) {
                return vm.$options.filters.toDateTime(
                  row.UtolsoBejelentkezesDatuma
                );
              }
              return 'Még nem jelentkezett be';
            },
          },
          {
            sTitle: 'Jogosultság',
            sClass: '',
            mRender: function(data, type, row, meta) {
              if (row.Jogosultsag) {
                return `<span class="badge badge-outline badge-primary font-weight-normal font-size-xs mb-1 mr-1">
                ${row.Jogosultsag.Text}
                </span>`;
              }
              return 'Nincs jogosultság';
            },
          },
          {
            sTitle: '',
            sClass: 'd-print-none dt-td-noClick all',
            bSortable: false,
            mRender: function(data, type, row, meta) {
              if (!vm.isProjektAdmin && !vm.isGod) {
                return '';
              }
              return '<i class="fa fa-edit pointer edit-user"  data-toggle="k-tooltip" title="Szerkesztés"></i>';
            },
          },
        ],
        pageLength: 25,
        responsive: false,
        deferRender: true,
        select: {
          style: 'multi',
          selector: 'td:first-child',
        },
        sDom: `<'row dt-panelmenu align-items-center'<'col-4 'i><'col-8 dt-search'<f>>> 
          <'row'<'col-sm-12'tr>>
          <'row dt-panelfooter mt-1'<'col-3' B><'col-9 dt-lp d-flex justify-content-end'l p>>`,
        //buttons: ['copy', 'excel', 'pdf', 'print'],
        drawCallback: function(settings) {
          var table = '#' + settings.sTableId + '_wrapper';
          var lastRowColSpan = settings.aoColumns.length;
          $('[data-toggle="k-tooltip"]').each(function() {
            addTooltip(this);
          });
        },
        createdRow: function(row, data, rowIndex) {
          $(row).attr('data-id', data.Id);
          $(row)
            .find('td')
            .addClass('pointer');
          $(row)
            .find('td')
            .not('.dt-td-noClick')
            .click(function(e) {
              let id = $(this)
                .closest('tr')
                .attr('data-id');
              if (!vm.isProjektAdmin && !vm.isGod) {
                return;
              }
              ModalFunctions.OpenModal('k-user-kezelo-modal', { id: id });
            });
          $(row)
            .find('.edit-user')
            .click(function(e) {
              var id = $(this)
                .closest('tr')
                .attr('data-id');
              if (!vm.isProjektAdmin && !vm.isGod) {
                return;
              }
              ModalFunctions.OpenModal('k-user-kezelo-modal', { id: id });
            });
        },
        buttons: [
          {
            extend: 'print',
            text: '<i class="fas fa-print text-grey"></i>',
            className: 'btn btn-primary',
          },
          {
            extend: 'excelHtml5',
            text: '<i class="fas fa-download mr-0 text-grey"></i>',
            className: 'btn btn-primary',
          },
        ],
      };

      return dtOptions;
    },
  },
  watch: {
    selected: {
      handler: async function(curr) {
        await this.$nextTick();
        this.DrawSelectsOnDT(curr);
      },
      deep: true,
    },
  },
  props: {
    selected: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
};
</script>
