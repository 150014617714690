<template>
  <div>
    <b-modal
      :key="modalKey"
      :id="modalKey"
      :hide-header="true"
      :hide-footer="true"
      size="lg"
      :hide-backdrop="backDrop"
      :no-close-on-backdrop="true"
      :dialog-class="dialogClass"
      :static="true"
      lazy
      :visible="modal.isOpened"
      ignore-enforce-focus-selector="#BottomFogvSearch"
      :modal-class="modal.isLoading ? 'modal-not-visible' : ''"
      @hidden="OnHidden"
      :style="{ 'z-index': 1040 + modal.idx }"
    >
      <div>
        <slot :events="{ Close: Close, SetLoading: SetLoading }"></slot>
      </div>
    </b-modal>
    <transition name="fade">
      <div class="modal-spinner" v-show="modal.isLoading">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ModalStoreTypes } from '../../../store/modules/modal';
import { useBaseModal } from '../../../utils/modal';

export default {
  name: 'base-modal',
  data() {
    return {
      key: 0,
    };
  },
  setup(props, context) {
    let { Close, modalInfo, SetLoading } = useBaseModal(props, context);

    // SetLoading(true);

    return {
      Close,
      modalInfo,
      SetLoading,
    };
  },
  mounted() {},
  created() {},
  methods: {
    OnHidden(e) {
      this.Close();
    },
  },
  computed: {
    ...mapGetters({ modals: ModalStoreTypes.getters.getModals }),
    modal() {
      return this.$store.getters[ModalStoreTypes.getters.getModalByName](
        this.modalName,
        this.idx
      );
    },
    backDrop() {
      return false;
    },
    modalKey() {
      return this.modalName;
    },
  },
  watch: {
    'modal.isOpened': function() {
      this.key++;
      this.$emit('update:value', false);
    },
  },
  props: {
    modalName: {
      type: String,
      required: true,
    },
    idx: {
      type: Number,
      required: true,
    },
    dialogClass: {
      type: String,
      default: 'modal-left',
    },
  },
};
</script>
<style>
.modal-spinner {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10000;
  width: 134px;
  height: 134px;
  border-radius: 25px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
.modal-not-visible {
  opacity: 0;
}
.modal-not-visible .modal-content {
  opacity: 0;
}
</style>
